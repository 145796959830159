import { render, staticRenderFns } from "./vsTable.vue?vue&type=template&id=d4c494b6&lang=html&"
import script from "./vsTable.vue?vue&type=script&lang=js&"
export * from "./vsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "vsTable.vue"
export default component.exports