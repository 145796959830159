export { default as vsButton } from './vsButton'
export { default as vsSelect } from './vsSelect'
export { default as vsSwitch } from './vsSwitch'
export { default as vsCheckbox } from './vsCheckBox'
export { default as vsRadio } from './vsRadio'
export { default as vsInput } from './vsInput'
export { default as vsTabs } from './vsTabs'
export { default as vsSlider } from './vsSlider'
export { default as vsInputNumber } from './vsInputNumber'
export { default as vsTooltip } from './vsTooltip'
export { default as vsUpload } from './vsUpload'
export { default as vsPopup } from './vsPopup'
export { default as vsAlert } from './vsAlert'
export { default as vsChip } from './vsChip'
export { default as vsProgress } from './vsProgress'
export { default as vsCard } from './vsCard'
export { default as vsList } from './vsList'
export { default as vsAvatar } from './vsAvatar'
export { default as vsPagination } from './vsPagination'
export { default as vsBreadcrumb } from './vsBreadcrumb'
export { default as vsPrompt } from './vsPrompt'
export { default as vsDivider } from './vsDivider'
export { default as vsSpacer } from './vsSpacer'
export { default as vsIcon } from './vsIcon'
export { default as vsNavbar } from './vsNavbar'
export { default as vsSideBar } from './vsSideBar'
export { default as vsDropDown } from './vsDropDown'
export { default as vsTable } from './vsTable'
export { default as vsTextarea } from './vsTextarea'
export { default as vsCollapse } from './vsCollapse'
export { default as vsImages } from './vsImages'
//New Component import

//layout
export { default as vsRow } from '../layout/vsRow/'
export { default as vsCol } from '../layout/vsCol/'
