<template>
  <div class="vs-navbar--items">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'VsNavbarItems'
}
</script>
