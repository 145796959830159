<template>
  <h3 class="vs-navbar--title">
    <slot></slot>
  </h3>
</template>
<script>
export default {
  name:'VsNavbarTitle',
}
</script>
