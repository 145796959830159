<template lang="html">
  <div
    class="vs-list">
    <slot/>
  </div>
</template>

<script>
export default {
  name:'VsList'
}
</script>
